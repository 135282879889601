import cake1 from "./cake-1.jpg";
import cake2 from "./cake-2.jpg";
import cake3 from "./cake-3.jpg";
import cake4 from "./cake-4.jpg";
import cake5 from "./cake-5.jpg";
import cake6 from "./cake-6.jpg";
import cake7 from "./cake-7.jpg";
import cake8 from "./cake-8.jpg";
import cake9 from "./cake-9.jpg";
import cake10 from "./cake-10.jpg";
import cake11 from "./cake-11.jpg";
import cake12 from "./cake-12.jpg";
import cake13 from "./cake-13.jpg";
import cake14 from "./cake-14.jpg";

export default [
  cake1,
  cake2,
  cake3,
  cake4,
  cake5,
  cake6,
  cake7,
  cake8,
  cake9,
  cake10,
  cake11,
  cake12,
  cake13,
  cake14,
];
