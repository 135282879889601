import Cakes from "./Cakes";
import pies from "../img/pies/pies";

function Products() {
  return (
    <>
      <div className="page-banner">
        <h2>Products</h2>
      </div>
      <div className="products-cat-title">
        <h2>Pies</h2>
      </div>
      <article className="pie-text">
        Our pies come in standard 10-inch deep dish size. Note that prices may
        be subject to seasonal change. Please ask about our diet-friendly
        options with no added sugar!{" "}
      </article>
      <br />
      <div className="main-page">
        <div className="product-grid-container">
          {pies.map((pie, i) => {
            console.log(pie);
            return (
              <div className="product-card" key={i}>
                <img src={pie.photo} width="95%" />
                <p className="product-name">{pie.name}</p>
                <p className="product-price">{pie.price}</p>
              </div>
            );
          })}
        </div>
      </div>
      <Cakes />
    </>
  );
}

export default Products;
