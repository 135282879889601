import React from "react";
// import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

// const useStyles = makeStyles((theme) => ({
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   //   paper: {
//   //     backgroundColor: theme.palette.background.paper,
//   //     border: '2px solid #000',
//   //     boxShadow: theme.shadows[5],
//   //     padding: theme.spacing(0.5, 0.5, 0),
//   //   },
//   image: {
//     maxWidth: "800px",
//   },
// }));

const TransitionsModal = (props) => {
  // const classes = useStyles();
  const classes = "";
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img onClick={handleOpen} src={props.imgSrc} />
      <Modal
        // aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {/* <h2 id="transition-modal-title">Transition modal</h2> */}
            <img src={props.imgSrc} className={classes.image} />
            {/* <p id="transition-modal-description">react-transition-group animates me.</p> */}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
