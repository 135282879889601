import pie1 from "./apple-pie.jpg";
import pie2 from "./blackbottom-pie.jpg";
import pie3 from "./blueberry-pie.jpg";
import pie4 from "./bostoncreme-pie.jpg";
import pie5 from "./bourbonpecan-pie.jpg";
import pie6 from "./buttermilk-pie.jpg";
import pie7 from "./cherry-pie.jpg";
import pie8 from "./chocolatechess-pie.jpg";
import pie9 from "./chocolatemeringue-pie.jpg";
import pie10 from "./coconutchess-pie.jpg";
import pie11 from "./coconutcream-pie.jpg";
import pie12 from "./coconutmeringue-pie.jpg";
import pie13 from "./dutchblueberry-pie.jpg";
import pie14 from "./eggcustard-pie.jpg";
import pie15 from "./keylime-pie.jpg";
import pie16 from "./lemonchess-pie.jpg";
import pie17 from "./lemonmeringue-pie.jpg";
import pie18 from "./pecan-pie.jpg";
import pie19 from "./pumpkin-pie.jpg";
import pie20 from "./pumpkincheese-pie.jpg";
import pie21 from "./strawberry-pie.jpg";
import pie22 from "./strawberryrhubarb-pie.jpg";
import pie23 from "./swtpotato-pie.jpg";

export default [
  {
    id: "pie-1",
    name: "Apple",
    price: "$17.00",
    photo: pie1,
  },
  {
    id: "pie-2",
    name: "Black Bottom",
    price: "$17.00",
    photo: pie2,
  },
  {
    id: "pie-3",
    name: "Blueberry",
    price: "$19.00",
    photo: pie3,
  },
  {
    id: "pie-4",
    name: "Boston Cream",
    price: "$17.00",
    photo: pie4,
  },
  {
    id: "pie-5",
    name: "Bourbon Pecan",
    price: "$21.00",
    photo: pie5,
  },
  {
    id: "pie-6",
    name: "Buttermilk",
    price: "$17.00",
    photo: pie6,
  },
  {
    id: "pie-7",
    name: "Cherry",
    price: "$18.00",
    photo: pie7,
  },
  {
    id: "pie-8",
    name: "Chocolate Chess",
    price: "$17.00",
    photo: pie8,
  },
  {
    id: "pie-9",
    name: "Chocolate Meringue",
    price: "$15.50",
    photo: pie9,
  },
  {
    id: "pie-10",
    name: "Coconut Chess",
    price: "$17.00",
    photo: pie10,
  },
  {
    id: "pie-11",
    name: "Coconut Cream",
    price: "$17.00",
    photo: pie11,
  },
  {
    id: "pie-12",
    name: "Coconut Meringue",
    price: "$17.00",
    photo: pie12,
  },
  {
    id: "pie-13",
    name: "Dutch Blueberry",
    price: "$19.00",
    photo: pie13,
  },
  {
    id: "pie-14",
    name: "Egg Custard",
    price: "$17.00",
    photo: pie14,
  },
  {
    id: "pie-15",
    name: "Key Lime",
    price: "$21.00",
    photo: pie15,
  },
  {
    id: "pie-16",
    name: "Lemon Chess",
    price: "$17.00",
    photo: pie16,
  },
  {
    id: "pie-17",
    name: "Lemon Meringue",
    price: "$17.00",
    photo: pie17,
  },
  {
    id: "pie-18",
    name: "Pecan",
    price: "$19.00",
    photo: pie18,
  },
  {
    id: "pie-19",
    name: "Pumpkin",
    price: "$17.00",
    photo: pie19,
  },
  {
    id: "pie-20",
    name: "Pumpkin Cheese",
    price: "$18.00",
    photo: pie20,
  },
  {
    id: "pie-21",
    name: "Strawberry",
    price: "$21.00",
    photo: pie21,
  },
  {
    id: "pie-22",
    name: "Strawberry Rhubarb",
    price: "$21.00",
    photo: pie22,
  },
  {
    id: "pie-23",
    name: "Sweet Potato",
    price: "$18.00",
    photo: pie23,
  },
];
