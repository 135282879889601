import {
  About,
  Faqs,
  Footer,
  Gallery,
  Homepage,
  Nav,
  Products,
} from "./components";
import "./styles/App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Nav />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="products" element={<Products />} />
          <Route path="about" element={<About />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="gallery" element={<Gallery />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      {/* <Router>
        <Nav />
        <div className="App">
          <Route path="/" exact component={Homepage} />
          <Route path="/products" component={Products} />
          <Route path="/about" component={About} />
          <Route path="/faqs" component={Faqs} />
          <Route path="/gallery" component={Gallery} />
          <Footer />
        </div>
      </Router> */}
    </>
  );
}

export default App;
