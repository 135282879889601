import cookie1 from "./cookie-1.jpg";
import cookie2 from "./cookie-2.jpg";
import cookie3 from "./cookie-3.jpg";
import cookie4 from "./cookie-4.jpg";
import cookie5 from "./cookie-5.jpg";
import cookie6 from "./cookie-6.jpg";
import cookie7 from "./cookie-7.jpg";
import cookie8 from "./cookie-8.jpg";

export default [
  cookie1,
  cookie2,
  cookie3,
  cookie4,
  cookie5,
  cookie6,
  cookie7,
  cookie8,
];
